import * as React from "react"
import ArticleSection from "../components/ArticleSection/ArticleSection"
import DefaultPageHeader from "../components/DefaultPageHeader/DefaultPageHeader"
import Line from "../components/Line/Line"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import { PageComponent } from "../types/gatsby.types"
import { ColorPalette } from "../utils/colors.utils"
import './about.scss'

const PageAbout: PageComponent = props => {
  return (
    <PageTemplate
      {...props} // always include
      className="PageAbout"
      title="About" 
      description="About AxonDivision: a full-service digital agency based in Dublin. We help you and your business succeed in this great acceleration of digitalisation with our unique blend of expertise in design, data and technology."
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >

      <DefaultPageHeader
        id="AboutPageHeader"
        title="About"
      >
        <Line />
      </DefaultPageHeader>

      <ArticleSection noMaxWidth>
        <div className="PageAboutArticleColumnSplit">
          <div>
            <p>trackster about content</p>
          </div>
          <div>
            <p><strong>Company No.</strong>: 526061</p>
            <p><strong>Registered Vat No.</strong>: 2976144QH</p>
            <br />
            <p><strong>Address</strong>: <br />AxonDivision, <br />133 Harold’s Cross, Dublin 6, <br />Republic of Ireland</p>
            <br />
            <p><strong>Office Landline</strong>: +353 (0)1 598 3936</p>
            <p><strong>Email</strong>: <a href="mailto:info@axondivision.com" title="Write us an email">info@axondivision.com</a></p>
          </div>
        </div>
      </ArticleSection>

      <Line />
    </PageTemplate>
  )
}

export default PageAbout
